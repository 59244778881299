import angularModule from '../front_royal_module';

// TODO: we should make a spec test that tests this controller explicitly
angularModule.controller('SiteCtrl', [
    '$scope',
    '$injector',
    function ($scope, $injector) {
        const $route = $injector.get('$route');
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');

        // used on the dynamic landing page.  Look for `app.dateHelper`
        this.dateHelper = $injector.get('dateHelper');

        $scope.site = () => {
            if (!$route.current) {
                return null;
            }

            return $route.current.site || 'public';
        };

        // wait for the first view to be rendered before
        // removing the loading spinner from the screen
        const cancelViewContentLoadedListener = $rootScope.$on('$viewContentLoaded', () => {
            cancelViewContentLoadedListener();
            $('.loading-front-royal').remove();
            $scope.readyToShow = true;
        });

        $rootScope.site = $scope.site;
        $scope.useShell = false;

        $scope.redirect = startPage => {
            if (startPage === 'editor') {
                if (!$location.path() || $location.path() === '/') {
                    $location.path('/editor');
                }
            } else {
                throw new Error('Can only start at default or editor.');
            }
        };
    },
]);
