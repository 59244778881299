import 'SoundManager/angularModule';

import patternBlueRepeat from 'images/pattern_blue_repeat.png';
import patternPurpleRepeat from 'images/pattern_purple_repeat.png';
import patternTurquoiseRepeat from 'images/pattern_turquoise_repeat.png';
import patternBeigeRepeat from 'images/pattern_beige_repeat.png';
import logoOnlyQuantic from 'vectors/logos/quantic-logo-only.svg';
import logoOnlyValar from 'vectors/logos/valar-logo-only.svg';
import logoOnlySmartly from 'vectors/logos/smartly-logo-only.svg';
import arrowRightBlackThin from 'vectors/arrow_right_black_thin.svg';
import arrowLeftBlackThin from 'vectors/arrow_left_black_thin.svg';

export default angular.module('FrontRoyal.AssetPreloader', ['SoundManager']).run([
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const SoundManager = $injector.get('SoundManager');
        const SoundConfig = $injector.get('SoundConfig');
        const $compile = $injector.get('$compile');
        const $timeout = $injector.get('$timeout');
        const $window = $injector.get('$window');

        function loadSounds() {
            SoundManager.loadUrl(SoundConfig.VALIDATE_CORRECT);
            SoundManager.loadUrl(SoundConfig.VALIDATE_INCORRECT);
            SoundManager.loadUrl(SoundConfig.DEFAULT_CLICK);
            SoundConfig.SCALING_CLICKS.forEach(scalingClick => {
                SoundManager.loadUrl(scalingClick);
            });
        }

        function loadImages() {
            const preload = [
                patternBlueRepeat,
                patternPurpleRepeat,
                patternTurquoiseRepeat,
                patternBeigeRepeat,
                logoOnlyQuantic,
                logoOnlyValar,
                logoOnlySmartly,
                arrowRightBlackThin,
                arrowLeftBlackThin,
            ];

            angular.forEach(preload, path => {
                const img = new Image();
                img.src = path;
            });
        }

        function loadBorderImages() {
            // add an element that includes all the styles that need
            // to be preloaded.  We've found with these border images that
            // if we don't actually load them in the same way we use them
            // in real life, the preloading does not actually work.  We can get
            // the image to load, but we still see a flash when we try to use
            // it the first time.  At least on mobile safari.
            const debug = false;

            let htmlText =
                '<in-dom-style-preloader style="position:absolute;left:0px;height:1px;overflow:hidden"></in-dom-style-preloader>';

            if (debug) {
                htmlText =
                    '<in-dom-style-preloader style="position:absolute;left:0px; z-index: 9999"></in-dom-style-preloader>';
            }
            const el = $(htmlText).prependTo('#wrap');

            // compile on given scope
            $compile(el)($rootScope);

            $timeout(() => {
                if (!debug) {
                    el.remove();
                }
            }, 3000);
        }

        // No need to preload as local disk IO should be performant
        if (!$window.CORDOVA) {
            // Wait until the first view has been rendered kicking off preload timer
            const cancelViewContentLoadedListener = $rootScope.$on('$viewContentLoaded', () => {
                cancelViewContentLoadedListener();

                // timer ensures visible content takes network connection precedence
                $timeout(() => {
                    loadSounds();
                    loadImages();
                    loadBorderImages();
                }, 2000);
            });
        }
    },
]);
