import NetworkConnection, { cancelRequestOnLostConnectionInterceptor } from 'NetworkConnection';
import { storeAutomationModeInLocalStorage } from 'automationMode';
import Selectize from 'selectize';
import { getBrandName, targetBrandConfig } from 'AppBranding';
import { storeCurrentUser } from 'FrontRoyalStore';
import { SegmentioHelper } from 'Segmentio';
import { TURNING_OFF_OFFLINE_MODE } from 'OfflineMode';
import { QUANTIC_CHINA_APP_DOMAIN } from 'PedagoDomainConstants';
import EventLoggerProvider from 'EventLogger/EventLoggerProvider';
import { angularInjectorProvider } from 'Injector';
import ErrorLogServiceProvider from 'ErrorLogging/ErrorLogServiceProvider';
import { initializeSendbirdSdk } from 'SendbirdSdkProvider';
import angularModule from './front_royal_module';
import './controllers/params_passthrough_ctrl';
import './controllers/site_ctrl';

// We shouldn't be running specs that load FrontRoyal application module
if (window.RUNNING_IN_TEST_MODE) {
    throw new Error(
        'Attempting to load FrontRoyal module in specs. You should only be loading isolated modules within specs.',
    );
}

const localDevelopmentMode = window.location.href.includes('localhost');

storeAutomationModeInLocalStorage();

// intercept default exception handling and provide passthrough via logging service
angularModule.provider('$exceptionHandler', {
    $get: ['ErrorLogService', ErrorLogService => ErrorLogService],
});

angularModule.config([
    '$injector',
    $injector => {
        const $animateProvider = $injector.get('$animateProvider');
        const $compileProvider = $injector.get('$compileProvider');
        const $httpProvider = $injector.get('$httpProvider');
        const $locationProvider = $injector.get('$locationProvider');
        const IguanaProvider = $injector.get('IguanaProvider');
        const httpRequestInterceptorCacheBusterProvider = $injector.get('httpRequestInterceptorCacheBusterProvider');
        const ngToastProvider = $injector.get('ngToastProvider');
        const hammerDefaultOptsProvider = $injector.get('hammerDefaultOptsProvider');
        const PrioritizedInterceptorsProvider = $injector.get('PrioritizedInterceptorsProvider');
        const setupFrontRoyalStoreInterceptors = $injector.get('setupFrontRoyalStoreInterceptors');

        // Add the LogInAsInterceptor to PrioritizedInterceptorsProvider only
        // when the FrontRoyal module in configured. This is atypical compared
        // to our other request interceptors.
        // See comments in vendor/front-royal/components/authentication/scripts/log_in_as_interceptor.js
        PrioritizedInterceptorsProvider.addInterceptor(-200, 'LogInAsInterceptor');

        // NOTE: see also - FrontRoyal.Navigation config for $routeProvider setup

        //-----------------------------
        // Setup segment snippet for Cordova:
        //  We don't actually load Analytics.js until networkReadyBootstrap down below.
        //  We only do this for Cordova because the snippet is installed for web in head scripts.
        //-----------------------------

        if (window.CORDOVA) {
            SegmentioHelper.snippet();
        }

        //-----------------------------
        // Configure Toasts
        //-----------------------------

        ngToastProvider.configure({
            horizontalPosition: 'center',
            timeout: 2500,
        });

        //-----------------------------
        // Setup Hammer Options
        //-----------------------------

        hammerDefaultOptsProvider.set({
            recognizers: [
                [
                    window.Hammer.Tap,
                    {
                        enable: true,
                    },
                ],
                [
                    window.Hammer.Pan,
                    {
                        enable: true,
                    },
                ],
                [
                    window.Hammer.Swipe,
                    {
                        enable: true,
                    },
                ],
                [
                    window.Hammer.Pinch,
                    {
                        enable: true,
                    },
                ],
                [
                    window.Hammer.Rotate,
                    {
                        enable: true,
                    },
                ],
                [
                    window.Hammer.Press,
                    {
                        enable: true,
                    },
                ],
            ],
        });

        //-----------------------------
        // $location Configuration
        //-----------------------------

        // Get rid of #hash in routes
        if (!window.CORDOVA) {
            $locationProvider.html5Mode(true);
        } else {
            // This is necessary for React Router to play nicely with angular.js router within Cordova
            $locationProvider.hashPrefix('');
        }

        //-----------------------------
        // Coalesce $http digests
        // see also: https://github.com/angular/angular.js/commit/ea6fc6e69c2a2aa213c71ed4e917a0d54d064e4c
        //-----------------------------

        $httpProvider.useApplyAsync(true);

        //-----------------------------
        // Whitelist ngAnimate Support
        //-----------------------------

        // restrict animation to elements with the animated css class with a regexp.
        $animateProvider.classNameFilter(/animated|ng-toast__message/);

        //-----------------------------
        // Configure Debug Mode
        //-----------------------------

        // disable debug information for production
        // see also: https://docs.angularjs.org/guide/production
        $compileProvider.debugInfoEnabled(localDevelopmentMode);

        // allow for csv download of lesson grader and for export of translated text
        $compileProvider.aHrefSanitizationTrustedUrlList(/^\s*(https?|mailto|blob|data*):/);

        // Sanitize (whitelist) any image urls, particularly capacitor
        $compileProvider.imgSrcSanitizationTrustedUrlList(/^\s*(https?|file|blob|capacitor):|data:image/);

        //-----------------------------
        // Configure IguanaProvider
        //-----------------------------

        // NOTE: is_content_item_mixin will also need to understand this ENDPOINT_ROOT prefix
        const uri = `${window.ENDPOINT_ROOT}/api`;
        IguanaProvider.setAdapter('Iguana.Adapters.RestfulIdStyle');
        IguanaProvider.setBaseUrl(uri);

        // by default, time requests out after 30 seconds
        IguanaProvider.setDefaultRequestOptions({
            timeout: 30 * 1000,
        });

        //-----------------------------
        // Configure cacheBustProvider
        //-----------------------------

        // see also: https://github.com/saintmac/angular-cache-buster
        const bustPattern = [];
        bustPattern.push(/\/api\//);
        httpRequestInterceptorCacheBusterProvider.setMatchlist(bustPattern, true);

        //-----------------------------
        // Setup interceptors
        //-----------------------------
        /*
            The FrontRoyalStore interceptors were breaking a bunch of specs that were
            totally unrelated to the FrontRoyalStore.  In order to prevent the interceptors
            from being automatically activated, we moved their setup to a function called
            setupFrontRoyalStoreInterceptors, which is only called here when we setup the
            actual app for use in the browser.

            This is currently not standard, but it actually should probably be how we always
            do things.  Lots of our modules have dependencies on other modules where today,
            just by requiring those dependencies, interceptor logic is setup that can complicate
            specs and take up time.  It is better if interceptors are only activated in the
            environments where we actually want them to be.  (For example, if we did this
            with HttpQueue, we should be able to get rid of calls in our specs to SpecHelper.disableHttpQueue)
        */
        setupFrontRoyalStoreInterceptors($injector);

        $httpProvider.interceptors.push(['$injector', cancelRequestOnLostConnectionInterceptor]);
    },
]);

angularModule.run([
    '$injector',

    $injector => {
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const isMobile = $injector.get('isMobile');
        const Frame = $injector.get('Lesson.FrameList.Frame');
        const EventLogger = $injector.get('EventLogger');
        const ClientConfig = $injector.get('ClientConfig');
        const ValidationResponder = $injector.get('ValidationResponder');
        const ConfigFactory = $injector.get('ConfigFactory');
        const SoundManager = $injector.get('SoundManager');
        const ShareService = $injector.get('Navigation.ShareService');
        const helpScoutBeacon = $injector.get('helpScoutBeacon');
        const StatusPoller = $injector.get('FrontRoyal.StatusPoller');
        const offlineModeManager = $injector.get('offlineModeManager');
        const Iguana = $injector.get('Iguana');
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const Locale = $injector.get('Locale');
        const ErrorLogService = $injector.get('ErrorLogService');
        const registrationWatcher = $injector.get('registrationWatcher');
        const ensureSendbirdToken = $injector.get('ensureSendbirdToken');

        Locale.initialize();

        // It might seem like a good idea to move this up into config so it's available earlier, but
        // that seems to be a different injector.
        angularInjectorProvider.injector = $injector;

        window.frontRoyalAppInjector = $injector;

        // We have to have this initiated before the first view renders
        // to make sure that 'RouteAnimationHelper.animationFinished' gets
        // fired on that first view
        $injector.get('RouteAnimationHelper').init();

        storeCurrentUser($injector);

        //---------------------------------
        // Determine Preview Mode Status
        //---------------------------------
        const inPreviewWindow = $location.url().includes('/editor/lesson/preview');
        const runningBot = $location.url().includes('/editor/bot');

        //---------------------------------
        // Frame Provider DI Pre-Fetching
        //---------------------------------

        // NOTE: ensure all dependencies are loaded prior to setting up injectables map
        $injector.get('Lesson');
        $injector.get('Lesson.LessonVersion');
        $injector.get('Lesson.FrameList');
        $injector.get('EntityMetadata');
        $injector.get('GlobalMetadata');

        Frame.mapInjectables({
            componentized: 'Lesson.FrameList.Frame.FlexibleComponentized',
            no_interaction: 'Lesson.FrameList.Frame.NoInteraction',
        });

        //---------------------------------
        // Event Logging
        //---------------------------------

        EventLoggerProvider.set(EventLogger);
        // uncomment for local debugging EventLoggerProvider
        // EventLoggerProvider.set({
        //     log: (...args) => {
        //         console.log(...args);
        //     },
        // });

        ErrorLogServiceProvider.set(ErrorLogService);

        // EventLogger can cause some issues in tests if turned on globally
        if (inPreviewWindow || runningBot) {
            EventLogger.disabled = true;
        }

        //---------------------------------
        // Mobile Click Tweaking
        //---------------------------------

        // use media query meta data to drive mobile-specific initialization
        if (isMobile()) {
            if ($window.addEventListener) {
                // could be mocked during tests

                // Prevent scrolling if a modal is open
                $window.addEventListener(
                    'touchmove',
                    event => {
                        if (
                            $window.document &&
                            $window.document.body &&
                            $window.document.body.classList.contains('modal-open')
                        ) {
                            // no more scrolling
                            event.preventDefault();
                        }
                    },
                    false,
                );
            }
        }

        //---------------------------------
        // SegmentIO Error Suppression HAX
        //---------------------------------

        const $log = $injector.get('$log');
        const origDebug = $log.debug;
        $log.debug = msg => {
            const filtered = [
                'Call segmentio API with',
                'Segmentio API initialized',
                'Segmentio API not yet initialized',
            ];

            // eslint-disable-next-line no-restricted-syntax
            for (const sub of filtered) {
                const length = sub.length;
                if (msg.substring(0, length) === sub) {
                    return;
                }
            }

            // eslint-disable-next-line no-undef
            origDebug.apply(this, arguments);
        };

        //---------------------------------
        // API HTTP Queing
        //---------------------------------

        $injector
            .get('HttpQueueAndRetry')
            .addFilter(config => config.url.includes('/api') && !config.url.includes('/api/auth'));

        //---------------------------------
        // SoundManager Initialization
        //---------------------------------

        const userSoundPrefWatcher = $rootScope.$watch('currentUser.pref_sound_enabled', () => {
            if ($rootScope.currentUser) {
                SoundManager.enabled = $rootScope.currentUser.pref_sound_enabled;
                userSoundPrefWatcher();
            }
        });

        //---------------------------------
        // Selectize Defaults
        //---------------------------------
        /*
            Selectize's `sortField` implementation does not properly sort Chinese
            characters.  (See the documentation for this on
            [this page under "sortField"](https://github.com/selectize/selectize.js/blob/master/docs/usage.md)
            and see [this issue](https://github.com/brianreavis/sifter.js/issues/68)
            that we created in the sifter.js library, which selectize uses)

            In any place where we have a selectize that has to support chinese
            options, we will have to set the `sortField` to `$order` and make sure
            that the options we pass in are properly sorted using `localeCompare`
        */

        Selectize.defaults.sortField = 'text';

        //---------------------------------
        // cordova-plugin-apprate
        //---------------------------------

        function initializeAppRating(config) {
            if ($window.AppRate) {
                const preferences = $window.AppRate.getPreferences();

                const MobileAppRateHelper = $injector.get('MobileAppRateHelper');

                // define callback handlers that get triggered during the use of cordova-plugin-apprate
                preferences.callbacks = {
                    onRateDialogShow() {
                        MobileAppRateHelper.incrementAppRatingPromptCounter();
                        MobileAppRateHelper.setLastAppRatingPromptAt();
                    },
                    handleNegativeFeedback() {
                        ShareService.share('app_feedback', 'email', {
                            title: `${getBrandName($rootScope.currentUser, config, 'short')} App Feedback`, // email subject line
                            to: [targetBrandConfig($rootScope.currentUser, config).emailAddressForUsername('feedback')],
                        });
                    },
                    onButtonClicked(buttonIndex, _currentBtn, promptName) {
                        if (
                            (promptName === 'AppRatingPrompt' && buttonIndex === 1) || // if the user responded negatively to the initial dialog modal
                            (promptName === 'StoreRatingPrompt' && buttonIndex === 3)
                        ) {
                            // if the user has chosen to rate the app

                            MobileAppRateHelper.setAppRatingPromptCounter(
                                MobileAppRateHelper.MAX_NUM_APP_RATING_PROMPTS,
                            );
                        }
                    },
                };

                $window.AppRate.setPreferences(preferences);

                // check if the user should be prompted for an app rating and do so if appropriate
                const listenerCanceler = $rootScope.$watch('currentUser', () => {
                    if ($rootScope.currentUser) {
                        MobileAppRateHelper.meetsRequirementsForMobileAppRatingPrompt($rootScope.currentUser).then(
                            meetsRequirementsForMobileAppRatingPrompt => {
                                if (meetsRequirementsForMobileAppRatingPrompt) {
                                    $window.AppRate.promptForRating(false);
                                }

                                // We only want this listener to execute once per app use, so we unbind it immediately after the first
                                // time it gets triggered, which should be when Cordova initializes. This prevents accidental prompting
                                // for an app reivew due to repeated login without actually closing the app.
                                listenerCanceler();
                            },
                        );
                    }
                });
            }
        }

        //---------------------------------
        // Network-Dependent Bootstrapping
        //---------------------------------

        function networkReadyBootstrap() {
            $rootScope.networkBootstrapStarted = true;
            ConfigFactory.getConfig().then(config => {
                // EventLogger configuration
                if (!EventLogger.disabled) {
                    // This must come before logStartEvent
                    EventLogger.setClientConfig(ClientConfig.current);

                    // log the page_load
                    EventLogger.logStartEvent();

                    EventLogger.trackLocationChanges();
                    EventLogger.trackFirstViewRender();
                    EventLogger.trackWindowFocusAndBlur();

                    // NOTE: Ori and Brent made an executive decision to disable these for now,
                    // as to reduce the torrent of events being logged
                    EventLogger.trackAllNgEvents('ngClick');
                    EventLogger.trackAllNgEvents('noApplyClick');
                    EventLogger.trackAllNgEvents('ngMousedown');
                    // EventLogger.trackFocusAndBlur();

                    // load Segment Analytics.js script for Cordova (it was already loaded for web in head scripts)
                    if ($window.CORDOVA && config.segmentioEnabled()) {
                        const segmentJsWriteKey = config.segmentJsWriteKeyForCordova();

                        if (offlineModeManager.inOfflineMode) {
                            offlineModeManager.once(TURNING_OFF_OFFLINE_MODE, () => {
                                window.analytics.load(segmentJsWriteKey);
                            });
                        } else {
                            window.analytics.load(segmentJsWriteKey);
                        }
                    }
                }

                // HTTP Queing Logging
                $injector.get('HttpQueue').instance._enableLogging = config.httpQueueEventsEnabled();

                // Store a flag indicating we've successfully initialized. This allows for
                // a minimally invasive way of displaying full-screen disconnection warnings
                // via FrontRoyalApiErrorHandler, prior to the app navigating initially, etc.
                $rootScope.networkBootstrapCompleted = true;

                initializeAppRating(config);

                //---------------------------------
                // Auth Initialization
                //---------------------------------
                if (inPreviewWindow) {
                    ValidationResponder.initializePreviewMode();
                } else {
                    ValidationResponder.initialize($rootScope.initiallyDisconnected);
                }

                // refers to https://trello.com/c/KfRiI6JW/67-feat-dynamically-use-appquanticcn-as-the-windowendpointroot-in-cordova-when-in-china
                if ($window.CORDOVA && config.forceChinaEndpointRootOnCordova()) {
                    // cordova never points to staging, so we can assume it's ok to use the prod endpoint here
                    window.ENDPOINT_ROOT = `https://${QUANTIC_CHINA_APP_DOMAIN}`;
                    const uri = `${window.ENDPOINT_ROOT}/api`;
                    // Iguana.baseUrl & ngDeviseTokenAuthClient.apiUrl have already been set in the angularModule.config() before the initial config API returned,
                    // (refers to /front-royal/scripts/app.js Line#174; /front-royal/modules/Authentication/angularModule/scripts/authentication_module.js Line#83)
                    // so need to reset them when the window.ENDPOINT_ROOT changed.
                    Iguana.setBaseUrl(uri);
                    ngDeviseTokenAuthClient.getConfig().apiUrl = uri;
                }

                // The ErrorLogService calls ensureSentryConfigured just-in-time when logging errors to
                // Sentry, but we need to eagerly configure Sentry here to make sure that we're ready
                // to do performance tracking with startTransaction
                ErrorLogService.ensureSentryConfigured();

                initializeSendbirdSdk(config.sendbirdApplicationId());
            });
        }

        // Before trying to load up the config, we need to check
        // if we can enable the store.  This will allow us to
        // fall back to the config stored in the store if necessary.
        offlineModeManager.canEnterOfflineMode().then(canEnterOfflineMode => {
            // If we are in Cordova with no network connection, show a message and
            // start polling for a connection.  Otherwise, go ahead and bootstrap
            if ($window.CORDOVA && !NetworkConnection.online && !canEnterOfflineMode) {
                // keep track of our initial failure to obtain a network connection
                // we provide this to ValidationResponder
                $rootScope.initiallyDisconnected = true;

                $location.path('/disconnected-mobile-init');

                const $interval = $injector.get('$interval');
                const networkDetectionInterval = $interval(
                    () => {
                        if (NetworkConnection.online) {
                            $interval.cancel(networkDetectionInterval);
                            networkReadyBootstrap();
                        }
                    },
                    1000,
                    false,
                );
            } else {
                networkReadyBootstrap();
            }
        });

        //---------------------------------
        // helpScoutBeacon
        //---------------------------------

        helpScoutBeacon.startWatching();

        //---------------------------------
        // Polling for updates from the server
        //---------------------------------

        StatusPoller.activate();

        //---------------------------------
        // Watching for registration to be completed so we can unset completedStripeCheckoutSession
        //---------------------------------
        registrationWatcher.watchForRegistration();

        // watching for if the user should have a sendbird token
        ensureSendbirdToken.watchForTokenNeeded();
    },
]);
