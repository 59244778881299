import 'ng-toast';
import 'oclazyload';
import 'ng-file-upload';
import 'angular-animate';
import 'angular-cookie';
import 'angular-cache-buster';

/*
    Due to the nature of angular modules and how they're simply statements that "register" a module
    with the app and any directives, services, etc. that the angular module may have, we can simply
    import the modules here just for their side-effects, meaning the module's global code gets run,
    effectively "registering" the angular module with the app, but without importing any values.
    We import these ES6 angular modules here rather than in the angular modules that actually list
    the angular module as a dependency to prevent the angular module from being "registered" more
    than once with the app. This strategy for importing modules is unique to our angular modules
    that have been ported over to ES6 module form. Traditional ES6 modules should be imported in
    the places they're needed as usual.
*/
import 'AdmissionsProcessModals/angularModule';
import 'AngularHttpQueueAndRetry/angularModule';
import 'Compile/angularModule';
import 'ErrorLogging/angularModule';
// eslint-disable-next-line quantic/only-relative-within-module
import 'FrontRoyalAssetPreloader/angularModule';
// eslint-disable-next-line quantic/only-relative-within-module
import 'FrontRoyalStore/angularModule';
// eslint-disable-next-line quantic/only-relative-within-module
import 'FrontRoyalWrapper/angularModule';
import 'Groups/angularModule';
import 'IguanaExtensions/angularModule';
import 'Injector/angularModule';
import 'IsMobile/angularModule';
import 'LearnerProjects/angularModule';
import 'MobileAppRateHelper/angularModule';
import 'NoApply/angularModule';
import 'Nominations/angularModule';
import 'OfflineMode/angularModule';
import 'Onboarding/angularModule';
import 'PrioritizedInterceptors/angularModule';
import 'ProgramSwitcher/angularModule';
import 'Resources/angularModule';
import 'RouteAnimationHelper/angularModule';
import 'ScrollHelper/angularModule';
import 'Settings/angularModule';
import 'Status/angularModule';
import 'StoredContent/angularModule';
import 'StudentNetwork/angularModule';
import 'Telephone/angularModule';
import 'Translation/angularModule';
import 'TuitionAndRegistrationSection/angularModule';
import 'TutorBot/angularModule';
import 'Messaging/angularModule';

// Performance tests are made available on the window so they can be run manually
import { DashboardNavigationTest, StoreStreamTest, LaunchLessonTest, FrameNavigationTest } from 'PerformanceTest';

window.DashboardNavigationTest = DashboardNavigationTest;
window.StoreStreamTest = StoreStreamTest;
window.LaunchLessonTest = LaunchLessonTest;
window.FrameNavigationTest = FrameNavigationTest;

// define all application dependencies (may be ordered)
const frontRoyalDependencies = [
    'AdmissionsProcessModals',
    'ClientStorage',
    'compileDir',
    'EventLogger',
    'FrontRoyal.ApiErrorHandler',
    'FrontRoyal.AssetPreloader',
    'FrontRoyal.Authentication',
    'FrontRoyal.Careers',
    'FrontRoyal.ContentItem',
    'FrontRoyal.ErrorLogService',
    'FrontRoyal.Groups',
    'FrontRoyal.iguanaExtensions',
    'FrontRoyal.Institutions',
    'FrontRoyal.LearnerProjects',
    'FrontRoyal.Lessons',
    'FrontRoyal.Linkedin',
    'FrontRoyal.Navigation',
    'FrontRoyal.Nominations',
    'FrontRoyal.Onboarding',
    'FrontRoyal.Playlists',
    'FrontRoyal.ProgramSwitcher',
    'FrontRoyal.Resources',
    'FrontRoyal.RouteAnimationHelper',
    'FrontRoyal.Settings',
    'FrontRoyal.Status',
    'FrontRoyal.StudentNetwork',
    'FrontRoyal.TutorBot',
    'FrontRoyal.UiBootstrap',
    'FrontRoyal.Users',
    'FrontRoyal.Wrapper',
    'FrontRoyalStore',
    'guid',
    'HelpScoutBeacon',
    'HttpQueueAndRetry',
    'Iguana.Adapters.RestfulIdStyle',
    'Iguana',
    'Injector',
    'isMobile',
    'Messaging',
    'MobileAppRateHelper',
    'ngAnimate',
    'ngCacheBuster',
    'ngFileUpload',
    'ngResource',
    'ngRoute',
    'ngToast',
    'noApply',
    'oc.lazyLoad',
    'OfflineMode',
    'prioritizedInterceptors',
    'scrollHelper',
    'StoredContent',
    'Translation',
    'TuitionAndRegistrationSection',
];

const frontRoyalAngularModule = angular.module('FrontRoyal', frontRoyalDependencies);
export default frontRoyalAngularModule;
